import { useState, useEffect } from 'react'
import CollectionContent from './CollectionContent'
import AddGameByIGDB from './AddGameByIGDB'
import AddGameManually from './AddGameManually'
import ModifyGame from './ModifyGame'
import '../../styles/Collection.css'

function Collection() {
  // État pour les menus
  const [currentView, setCurrentView] = useState('') // Peut être 'collection', 'addGameMenu', 'addGame', 'addManualGame', 'modifyGame'
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [authenticated, setAuthenticated] = useState(false)
  const [gameToModify, setGameToModify] = useState(null)

  const showMenu = (menu) => {
    setCurrentView(menu)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handlePasswordSubmit = (event) => {
    event.preventDefault()
    const correctPassword = process.env.REACT_APP_COLLECTION_PASSWORD

    if (password === correctPassword) {
      setAuthenticated(true)
      setError('')
    } else {
      setError('Le mot de passe est incorrect')
    }
  }

  useEffect(() => {
    document.title = "NG's Collection"

    const metaNoIndex = document.createElement('meta')
    metaNoIndex.name = 'robots'
    metaNoIndex.content = 'noindex'
    document.head.appendChild(metaNoIndex)

    return () => {
      document.head.removeChild(metaNoIndex)
    }
  }, [])

  useEffect(() => {
    if (gameToModify !== null) {
      showMenu('modifyGame')
    }
  }, [gameToModify])

  const handleModifyGame = (gameId) => {
    setGameToModify(gameId)
  }

  return (
    <div>
      <h2>Collection</h2>
      {!authenticated ? (
        <form className="collection-psw-form" onSubmit={handlePasswordSubmit}>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Entrez le mot de passe"
            autoFocus
          />
          <button type="submit">Valider</button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </form>
      ) : (
        <div>
          {currentView === '' && (
            <div>
              <button
                className="menu-button"
                onClick={() => showMenu('collection')}
              >
                Accéder à la collection
              </button>
              <button
                className="menu-button"
                onClick={() => showMenu('addGameMenu')}
              >
                Ajouter un jeu
              </button>
            </div>
          )}
          {currentView === 'addGameMenu' && (
            <div>
              <button
                className="menu-button"
                onClick={() => showMenu('addGame')}
              >
                Ajouter un jeu par IGDB
              </button>
              <button
                className="menu-button"
                onClick={() => showMenu('addManualGame')}
              >
                Ajouter un jeu manuellement
              </button>
              <button className="menu-button" onClick={() => showMenu('')}>
                Retour
              </button>
            </div>
          )}
          {currentView === 'addGame' && (
            <div>
              <button className="menu-button" onClick={() => showMenu('')}>
                Retour
              </button>
              <AddGameByIGDB onModifyGame={handleModifyGame} />
            </div>
          )}
          {currentView === 'addManualGame' && (
            <div>
              <button className="menu-button" onClick={() => showMenu('')}>
                Retour
              </button>
              <AddGameManually />
            </div>
          )}
          {currentView === 'modifyGame' && (
            <div>
              <button className="menu-button" onClick={() => showMenu('')}>
                Retour
              </button>
              <ModifyGame gameId={gameToModify} />
            </div>
          )}
          {currentView === 'collection' && (
            <div>
              <button className="menu-button" onClick={() => showMenu('')}>
                Fermer
              </button>
              <CollectionContent />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Collection
