import { useState } from 'react'

function CollectionContentOptions({
  includeFreeGames,
  includePSPlusGames,
  owners,
  platforms,
  selectedOwners,
  selectedPlatforms,
  onIncludeFreeGamesChange,
  onIncludePSPlusGamesChange,
  onSortChange,
  onOwnerChange,
  onPlatformChange,
  onSearchChange, // Nouvelle prop pour gérer la recherche
}) {
  const [sortOption, setSortOption] = useState('latest')
  const [searchTerm, setSearchTerm] = useState('') // Nouvel état pour le champ de recherche

  const handleSortChange = (e) => {
    const selectedOption = e.target.value
    setSortOption(selectedOption)
    onSortChange(selectedOption)
  }

  const handleSearchChange = (e) => {
    const searchValue = e.target.value
    setSearchTerm(searchValue)
    onSearchChange(searchValue)
  }

  return (
    <div className="collection-options-container">
      <label>
        <input
          type="checkbox"
          checked={includeFreeGames}
          onChange={(e) => onIncludeFreeGamesChange(e.target.checked)}
        />
        &nbsp;Inclure les jeux gratuits
      </label>
      <label>
        <input
          type="checkbox"
          checked={includePSPlusGames}
          onChange={(e) => onIncludePSPlusGamesChange(e.target.checked)}
        />
        &nbsp;Inclure les jeux PlayStation Plus
      </label>
      <div className="owner-filters">
        <h3>Filtrer par propriétaire :</h3>
        {owners.map((owner) => (
          <label key={owner.id}>
            <input
              type="checkbox"
              value={owner.id}
              checked={selectedOwners.includes(owner.id)}
              onChange={(e) =>
                onOwnerChange(parseInt(e.target.value), e.target.checked)
              }
            />
            &nbsp;{owner.name}
          </label>
        ))}
      </div>
      <div className="platform-filters">
        <h3>Filtrer par plateformes :</h3>
        {platforms.map((platform) => (
          <label key={platform.id}>
            <input
              type="checkbox"
              value={platform.id}
              checked={selectedPlatforms.includes(platform.id)}
              onChange={(e) =>
                onPlatformChange(parseInt(e.target.value), e.target.checked)
              }
            />
            &nbsp;{platform.name}
          </label>
        ))}
      </div>
      <label className="order-by">
        <h3>Trier par : </h3>
        <select value={sortOption} onChange={handleSortChange}>
          <option value="latest">Derniers ajouts</option>
          <option value="alphabetical">Ordre alphabétique</option>
        </select>
      </label>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Rechercher un jeu..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
    </div>
  )
}

export default CollectionContentOptions
