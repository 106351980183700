import { useState, useEffect } from 'react'

function AddGameByIGDB({ onModifyGame }) {
  const [igdbId, setIgdbId] = useState('')
  const [gameData, setGameData] = useState(null)
  const [error, setError] = useState('')
  const [addedGameId, setAddedGameId] = useState(null)
  const [isFree, setIsFree] = useState(false)
  const [comments, setComments] = useState('')
  const [platforms, setPlatforms] = useState([])
  const [subplatforms, setSubplatforms] = useState([])
  const [owners, setOwners] = useState([])
  const [selectedPlatform, setSelectedPlatform] = useState('')
  const [selectedOwner, setSelectedOwner] = useState('')
  const [selectedSubplatform, setSelectedSubplatform] = useState('')
  const [playstationPlus, setPlaystationPlus] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false) // État pour suivre le chargement

  useEffect(() => {
    const controller = new AbortController() // Crée un AbortController pour annuler les fetches
    const signal = controller.signal

    async function fetchData() {
      try {
        const platformsResponse = await fetch('/api/pg/platforms', { signal })
        const platformsData = await platformsResponse.json()
        setPlatforms(platformsData)

        const subplatformsResponse = await fetch('/api/pg/subplatforms', {
          signal,
        })
        const subplatformsData = await subplatformsResponse.json()
        setSubplatforms(subplatformsData)

        const ownersResponse = await fetch('/api/pg/owners', { signal })
        const ownersData = await ownersResponse.json()
        setOwners(ownersData)
      } catch (err) {
        if (err.name !== 'AbortError') {
          // Ignore l'erreur si la requête a été annulée
          console.error(
            'Error fetching platforms, subplatforms, or owners',
            err
          )
        }
      }
    }

    fetchData()

    return () => {
      controller.abort() // Annule les requêtes si le composant est démonté
    }
  }, [])

  const handleChange = (e) => {
    setIgdbId(e.target.value)
  }

  const handleFetchGame = async (e) => {
    e.preventDefault()
    setError('')
    setGameData(null)

    try {
      // Vérifier si le jeu existe déjà dans la base de données
      const gamesResponse = await fetch('/api/pg/games')
      const gamesData = await gamesResponse.json()
      const existingGame = gamesData.find((game) => game.igdb_id === igdbId)

      if (existingGame) {
        setError({
          message: 'Ce jeu existe déjà dans la BDD',
          gameId: existingGame.id,
        })
      } else {
        // Sinon, récupérer les données depuis IGDB
        const response = await fetch(`/api/fetch-game/${igdbId}`)
        const data = await response.json()

        if (response.ok && data) {
          setGameData(data)
          // setAddedGameId(gameId)
        } else if (response.status === 404) {
          setError('Jeu non trouvé')
        }
      }
    } catch (err) {
      setError('Erreur lors de la récupération des données')
      console.error(err)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true) // Début du chargement

    // Validation des champs
    if (!selectedPlatform || !selectedOwner) {
      alert('Veuillez sélectionner une plateforme et un possesseur.')
      setLoading(false) // Fin du chargement en cas d'erreur de validation
      return
    }

    try {
      // Ajouter le jeu à la table `games`
      const response = await fetch('/api/add-game', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          igdb_id: gameData.id,
          name: gameData.name,
          is_free: isFree,
          addition_date: new Date().toISOString(),
          comments: comments,
        }),
      })

      const result = await response.json()

      if (response.ok) {
        // Ajouter les informations dans `game_platforms`
        const gameId = result.game.id
        setAddedGameId(gameId) // Enregistre l'ID du jeu ajouté
        const gamePlatformResponse = await fetch('/api/add-game-platform', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            game_id: gameId,
            possessions: [
              {
                platform_id: selectedPlatform,
                playstation_plus: playstationPlus,
                owner_id: selectedOwner,
                subplatform_id: selectedSubplatform || null, // Envoyer null si aucune sous-plateforme n'est sélectionnée
              },
            ],
          }),
        })

        const gamePlatformResult = await gamePlatformResponse.json()

        if (gamePlatformResponse.ok) {
          setSuccess(true)
          setIgdbId('')
          setGameData(null)
          setIsFree(false)
          setComments('')
          setSelectedPlatform('')
          setSelectedOwner('')
          setSelectedSubplatform('')
          setPlaystationPlus(false)
        } else {
          alert('Erreur: ' + gamePlatformResult.error)
        }
      } else {
        alert('Erreur: ' + result.error)
      }
    } catch (err) {
      alert("Erreur lors de l'ajout du jeu")
      console.error(err)
    } finally {
      setLoading(false) // Fin du chargement
    }
  }

  return (
    <div>
      {!success ? (
        <>
          <form onSubmit={handleFetchGame}>
            <label>
              IGDB ID : &nbsp;
              <input
                type="text"
                value={igdbId}
                onChange={handleChange}
                required
              />
            </label>
            <button type="submit">Récupérer les Infos du Jeu</button>
          </form>
          {error && (
            <div style={{ color: 'red' }}>
              <p>{error.message}</p>
              {error.gameId && (
                <button onClick={() => onModifyGame(error.gameId)}>
                  Modifier ce jeu
                </button>
              )}
            </div>
          )}

          {gameData && (
            <form className="add-game-form" onSubmit={handleSubmit}>
              <h3>{gameData.name}</h3>
              <label>
                <input
                  type="checkbox"
                  checked={isFree}
                  onChange={(e) => setIsFree(e.target.checked)}
                />
                &nbsp;Gratuit
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={playstationPlus}
                  onChange={(e) => setPlaystationPlus(e.target.checked)}
                />
                &nbsp;Playstation Plus
              </label>
              <label>
                Possesseur : &nbsp;
                <select
                  value={selectedOwner}
                  onChange={(e) => setSelectedOwner(e.target.value)}
                  required
                >
                  <option value="">Sélectionner un possesseur</option>
                  {owners.map((owner) => (
                    <option key={owner.id} value={owner.id}>
                      {owner.name}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Plateforme :&nbsp;
                <select
                  value={selectedPlatform}
                  onChange={(e) => setSelectedPlatform(e.target.value)}
                  required
                >
                  <option value="">Sélectionner une plateforme</option>
                  {platforms.map((platform) => (
                    <option key={platform.id} value={platform.id}>
                      {platform.name}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Sous-plateforme (optionnelle) : &nbsp;
                <select
                  value={selectedSubplatform}
                  onChange={(e) => setSelectedSubplatform(e.target.value)}
                >
                  <option value="">Sélectionner une sous-plateforme</option>
                  {subplatforms.map((subplatform) => (
                    <option key={subplatform.id} value={subplatform.id}>
                      {subplatform.name}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                <span className="text-input-name">Commentaires :</span>
                <textarea
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  placeholder="Ajoutez un commentaire"
                />
              </label>
              <button type="submit" disabled={loading}>
                {loading ? 'Ajout en cours...' : 'Ajouter le Jeu'}
              </button>
            </form>
          )}
        </>
      ) : (
        <div>
          <p>Jeu ajouté avec succès !</p>
          <button onClick={() => onModifyGame(addedGameId)}>
            Modifier ce jeu
          </button>
        </div>
      )}
    </div>
  )
}

export default AddGameByIGDB
