function Footer() {
  const currentYear = new Date().getFullYear()

  return (
    <footer>
      © {currentYear} Nicolas Goberville
      <br />{' '}
      <a href="mailto:goberville.nicolas@gmail.com">
        {' '}
        goberville.nicolas@gmail.com
      </a>
    </footer>
  )
}

export default Footer
