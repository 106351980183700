import { useState, useEffect } from 'react'
import GameInfoModal from './GameInfoModal'
import default_cover from '../../assets/images/collection/default_cover.png'

function getPlatformsForGame(gameId, gamesPlatforms, platforms, subplatforms) {
  const gamePlatformLinks = gamesPlatforms.filter((gp) => gp.game_id === gameId)

  const platformsToSubplatforms = gamePlatformLinks.map((gamePlatformLink) => {
    // Récupérer la plateforme associée
    const platform = platforms.find(
      (p) => p.id === gamePlatformLink.platform_id
    )

    // Récupérer les sous-plateformes directement à partir du champ subplatform_id dans gamesPlatforms
    const associatedSubplatforms = gamePlatformLink.subplatform_id
      ? subplatforms.filter((sp) => sp.id === gamePlatformLink.subplatform_id)
      : []

    return {
      ...platform,
      subplatforms: associatedSubplatforms,
      isOnlyPSPlus: gamePlatformLink.playstation_plus,
    }
  })

  return platformsToSubplatforms
}

function CollectionContentGames({
  games,
  gamesPlatforms,
  platforms,
  subplatforms,
  owners,
  onModifyGame,
}) {
  const [selectedGame, setSelectedGame] = useState(null)
  const [gamesData, setGamesData] = useState({})
  const [coverUrls, setCoverUrls] = useState({})

  const handleGameClick = (game) => {
    setSelectedGame(game)
  }

  const handleCloseModal = () => {
    setSelectedGame(null)
  }

  const fetchGameData = async (igdbId) => {
    try {
      const response = await fetch(`/api/fetch-game/${igdbId}`)
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des données IGDB')
      }
      const data = await response.json()
      return data
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const fetchCoverUrl = async (coverId) => {
    try {
      const response = await fetch(
        `/api/fetch-cover/${coverId}?size=t_logo_med`
      )
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération de la couverture')
      }
      const data = await response.json()
      return data.url ? `${data.url}` : null
    } catch (error) {
      console.error('Error fetching cover URL:', error)
      return null
    }
  }

  useEffect(() => {
    let isMounted = true

    const fetchGameDataAndCover = async (game) => {
      const gamesDataTemp = {}
      const coverUrlsTemp = {}

      if (game.igdb_id) {
        // Logique existante pour récupérer depuis IGDB
        const gameData = await fetchGameData(game.igdb_id)
        if (gameData) {
          gamesDataTemp[game.id] = gameData
          if (gameData.cover) {
            const coverUrl = await fetchCoverUrl(gameData.cover)
            if (coverUrl) {
              coverUrlsTemp[game.id] = coverUrl
            }
          }
        }
      } else if (game.cover_url) {
        // Utilise la couverture fournie directement dans cover_url
        coverUrlsTemp[game.id] = game.cover_url
      }

      if (isMounted) {
        // Met à jour chaque jeu individuellement
        setGamesData((prevData) => ({
          ...prevData,
          [game.id]: gamesDataTemp[game.id],
        }))

        setCoverUrls((prevUrls) => ({
          ...prevUrls,
          [game.id]: coverUrlsTemp[game.id],
        }))
      }
    }

    // Pour chaque jeu, on fetch et on met à jour immédiatement
    games.forEach((game) => {
      fetchGameDataAndCover(game)
    })

    return () => {
      isMounted = false
    }
  }, [games])

  return (
    <div className="collection-games-grid">
      {games.map((game) => {
        const gameData = gamesData[game.id]
        const coverUrl = coverUrls[game.id]

        return (
          <div key={game.id} className="game-item">
            <h2 className="game-title" onClick={() => handleGameClick(game)}>
              {gameData ? gameData.name : game.name}
            </h2>
            <img
              src={coverUrl || default_cover} // coverUrl peut être IGDB ou cover_url du jeu, sinon default_cover
              alt={gameData ? gameData.name : 'Cover'}
              className="game-cover"
              onClick={() => handleGameClick(game)}
            />
          </div>
        )
      })}

      {selectedGame && (
        <GameInfoModal
          game={selectedGame}
          onClose={handleCloseModal}
          platforms={getPlatformsForGame(
            selectedGame.id,
            gamesPlatforms,
            platforms,
            subplatforms
          )}
          gamesPlatforms={gamesPlatforms}
          owners={owners}
          onModifyGame={onModifyGame} // Passe la fonction
        />
      )}
    </div>
  )
}

export default CollectionContentGames
