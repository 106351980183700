import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import Prod from './Prod'
import Collection from './collection/Collection'
import '../styles/normalize.css'
import '../styles/skeleton.css'
import '../styles/Home.css'

function App() {
  return (
    <Router>
      <div id="main-div">
        <Header />
        <Routes>
          {/* Route principale */}
          <Route path="/" element={<Prod />} />

          {/* Page cachée */}
          <Route path="/collection" element={<Collection />} />

          {/* Route de fallback pour les pages non trouvées */}
          <Route path="*" element={<RedirectToExternal />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  )
}

// Composant pour rediriger vers www.monsite.com en préservant le chemin de l'URL
function RedirectToExternal() {
  const currentPath =
    window.location.pathname + window.location.search + window.location.hash
  window.location.replace(`https://www.ngoberville.com${currentPath}`)
  return null
}

export default App
